import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import './dashboard.less';
import { Col, Row, Typography } from 'antd';
import CustomButton from '../../../../common/ui/Button/CustomButton';
import DashboardHeader from './DashboardHeader';
import UpcomingLessons from './UpcomingLessons';
import CurrentDay from './CurrentDay';
import StudentCalendar from './StudentCalendar';
const DashboardScreen = () => {
  return (
    <>
      <Row className='dashboard-main'>
        <Col span={24} style={{ padding: '2%' }}>
          <DashboardHeader />
        </Col>
        <Col span={24} style={{ padding: '2%' }}>
          <UpcomingLessons />
          <Link to={APP_ROUTES.FINDTUTOR}>
            <CustomButton label={'Find Tutor'} />
          </Link>
        </Col>
        <Col sapn={24}>
          <Row>
            <Col span={12}>
              <StudentCalendar />
            </Col>
            <Col span={12}>
              <CurrentDay />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default DashboardScreen;
