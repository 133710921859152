import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import './dashboard.less';
import { Col, Row, Typography, Image } from 'antd';
import CustomButton from '../../../../common/ui/Button/CustomButton';
import SvgConverter from '../../../../common/ui/SvgConvertor';
// import { ReactComponent as ListImage } from '../../../../common/icons/upcoming-image.svg';
import ListImage from '../../../../common/icons/upcoming-image.svg';
const ListContent = ({ time }) => {
  return (
    <Col span={24} className='lessons-list-col'>
      <Row>
        <Col xl={6} md={8} sm={24}>
          <Typography className='list-time'> {time}</Typography>{' '}
          <Typography className='list-date'>Tuesday, April 24, 2023</Typography>
          <CustomButton
            label={'Upcoming'}
            className={'common-button button-upcoming'}
          />
        </Col>
        <Col xl={10} md={8} sm={24}>
          <Typography className='list-time'>
            ACT, 30 Minutes of Lessons
          </Typography>
          <Typography className='list-date'>OrderID: #12334</Typography>
        </Col>
        <Col xl={8} md={8} sm={24}>
          <Row>
            <Col span={5}>
              <Image src={ListImage} preview={false} />
            </Col>
            <Col span={12}>
              <Typography className='tutor-name'>Hamico Jins</Typography>{' '}
              <Typography className='tutor'>Tutor</Typography>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col span={6}>
              {' '}
              <CustomButton label={'Cancel'} />
            </Col>{' '}
            <Col span={12}>
              <CustomButton
                label={'Enter Classroom'}
                className={'common-button'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default ListContent;
