import React from 'react';
import { Modal } from 'antd';
const ReusableModal = ({ open, onCancel, onOk, title, children, width }) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      title={title}
      width={width}
    >
      {children}
    </Modal>
  );
};
export default ReusableModal;
