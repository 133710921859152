import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { APP_ROUTES, AUTH_ROUTES } from '../common/constants/Routes';
import AppLayout from './AppLayout';
import MyTutorScreen from '../features/App/Student/MyTutor/MyTutorScreen';
import HistoryScreen from '../features/App/Student/History/HistoryScreen';
import StudentSignIn from '../features/Auth/StudentSignIn/StudentSignIn';
import HelpScreen from '../features/App/Student/Help/HelpScreen';
import DashboardScreen from '../features/App/Student/Dashboard/DashboardScreen';
import StudentLoginScreen from '../features/Auth/StudentLogin/StudentLoginscreen';
import FindTutorScreen from '../features/App/Student/Dashboard/FindTutor/FindTutorScreen';
import TutorProfile from '../features/App/Student/MyTutor/TutorProfile';
import BookingConfirmed from '../features/App/Student/MyTutor/BookingConfirmed';

const CommonRoutes = () => {
  const [login, setLogin] = useState(false);
  const isLoggedIn = localStorage.getItem('login');
  return (
    <Routes>
      <Route
        path={AUTH_ROUTES.STUDENT_LOGIN}
        element={<StudentLoginScreen />}
      />
      <Route exact path={APP_ROUTES.SIGNUP} element={<StudentSignIn />} />
      <Route path={APP_ROUTES.APPLAYOUT} element={<AppLayout />}>
        <Route
          exact
          path={APP_ROUTES.DASHBOARD}
          element={<DashboardScreen />}
        />
        <Route exact path={APP_ROUTES.MYTUTOR} element={<MyTutorScreen />} />
        <Route exact path={APP_ROUTES.HISTORY} element={<HistoryScreen />} />
        <Route exact path={APP_ROUTES.HELP} element={<HelpScreen />} />
        <Route
          exact
          path={APP_ROUTES.FINDTUTOR}
          element={<FindTutorScreen />}
        />
        <Route
          exact
          path={APP_ROUTES.TUTORPROFILE}
          element={<TutorProfile />}
        />
        <Route
          exact
          path={APP_ROUTES.BOOKINGPAGE}
          element={<BookingConfirmed />}
        />
      </Route>
    </Routes>
  );
};
export default CommonRoutes;
