export const AUTH_ROUTES = {
  STUDENT_LOGIN: '/',
  SIGNUP: '/signup',
};

export const APP_ROUTES = {
  APPLAYOUT: '/student',
  DASHBOARD: '/student/dashboard',
  MYTUTOR: '/student/mytutor',
  HISTORY: '/student/history',
  SIGNIN: '/signIn',
  HELP: '/student/help',
  // STUDENT_LOGIN: '/',
  SIGNUP: '/signup',
  FINDTUTOR: '/student/findtutor',
  LOGIN: '/student/login',
  TUTORPROFILE: '/student/mytutor/TutorProfile',
  BOOKINGPAGE: '/student/mytutor/booking',
};
export const WITHOUT_LAYOUT = [
  APP_ROUTES.SIGNIN,
  APP_ROUTES.SIGNUP,
  APP_ROUTES.BOOKINGPAGE,
  APP_ROUTES.FINDTUTOR,
];
