import React from 'react';
import { useTheme } from 'antd';

const SvgConverter = ({
  Icon = false,
  className = '',
  color = 'black',
  disabled = false,
  width = 0,
  height = 0,
  ...rest
}) => {
  return (
    <>
      {Icon ? (
        <Icon
          className={`icon-converter ${className} color-${
            !disabled ? color : 'muted'
          }`}
          {...(width ? { width } : {})}
          {...(height ? { height } : {})}
          {...rest}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SvgConverter;
