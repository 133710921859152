import React from 'react'
import {  Col, Row } from 'antd';
import { ReactComponent as SearchIcon } from "../../../../common/images/searchicon.svg";
import './History.less';
import FormInput from '../../../../common/ui/Input/FormInput';
function HistoryHeader() {
  return (
    <div>
    <Row justify="space-between" align="middle">
        <Col sm={12} xs={8} md={10} lg={8} xl={6} xxl={5}>
          <h1 className="row_h1">My Classes</h1>
        </Col>
        <Col md={0} lg={0} xl={5} xxl={13}></Col>
        <Col sm={1} xs={10} md={6} lg={5} xl={4} xxl={3}>
        
      <FormInput addonBefore={<SearchIcon  className="search-icon" />} className="search-box" placeholder="Type order ID or Class" />
       
        </Col>
      </Row>  
    </div>
  )
}

export default HistoryHeader
