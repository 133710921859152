// import React from 'react';
// import { Form, Input, Button, Row, Col, Typography } from 'antd';
// import CustomButton from '../../../common/ui/Button/CustomButton';
// import { Link } from 'react-router-dom';
// const LoginForm = () => {
//   const onFinish = (values) => {
//     console.log('Received values of form: ', values);
//     console.log(' form: ', values.length);
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log('Failed:', errorInfo);
//   };
//   return (
//     <>
//       <Form
//         name='login'
//         initialValues={{ remember: true }}
//         onFinish={onFinish}
//         onFinishFailed={onFinishFailed}
//       >
//         <Form.Item
//           name='email'
//           rules={[{ required: true, message: 'Please input your email!' }]}
//         >
//           <Input className='input-box' placeholder='Your Mail' />
//         </Form.Item>

//         <Form.Item>
//           <CustomButton
//             label={'NEXT'}
//             className={'but_col button'}
//             block={true}
//             htmlType={'submit'}
//           />
//         </Form.Item>

//         <Form.Item>
//           <Row style={{ justifyContent: 'center' }}>
//             <Col> No account yet?</Col>{' '}
//             <Col style={{ marginLeft: '2%' }}>
//               <Link to='#'>Register</Link>
//             </Col>
//           </Row>
//         </Form.Item>
//       </Form>
//     </>
//   );
// };

// export default LoginForm;

import React, { useState } from 'react';
import { Form, Input, Button, Row, Typography, Col } from 'antd';
import CustomButton from '../../../common/ui/Button/CustomButton';
import './StudentLogin.less';
import { Link, useNavigate } from 'react-router-dom';

import AppLayout from '../../../Layout/AppLayout';
import { APP_ROUTES } from '../../../common/constants/Routes';
const LoginForm = (setLogin) => {
  const [showFirstForm, setShowFirstForm] = useState(true);
  const [showSecondForm, setShowSecondForm] = useState(false);
  const [firstFormValues, setFirstFormValues] = useState({});
  const [secondFormValues, setSecondFormValues] = useState({});
  const navigate = useNavigate();
  const handleFirstFormSubmit = (values) => {
    setFirstFormValues(values);
    setShowFirstForm(false);
    setShowSecondForm(true);
  };

  const handleSecondFormSubmit = (values) => {
    setSecondFormValues(values);
    console.log('values', values);
    // navigate(<AppLayout />);
    localStorage.setItem('login-user', values.password);
    localStorage.setItem('user', true);
    navigate(APP_ROUTES.DASHBOARD);
  };

  return (
    <>
      {showFirstForm && (
        <Form onFinish={handleFirstFormSubmit}>
          <Form.Item
            name='email'
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input className='input-box' placeholder='Your Mail' />
          </Form.Item>
          <Form.Item>
            <CustomButton
              label={'NEXT'}
              className={'but_col button'}
              block={true}
              htmlType={'submit'}
            />
          </Form.Item>
        </Form>
      )}

      {showSecondForm && (
        <Form onFinish={handleSecondFormSubmit}>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password className='input-box' placeholder='Your Password' />
          </Form.Item>{' '}
          <div className='forgot-password'>
            <p>
              <a className='ForgotPassword' href='#'>
                Forgot Password?
              </a>
            </p>
          </div>
          <Form.Item>
            <CustomButton
              label={'LOGIN'}
              className={'but_col button'}
              block={true}
              htmlType={'submit'}
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default LoginForm;
