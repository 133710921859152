import DashboardScreen from '../../features/App/Student/Dashboard/DashboardScreen';
import MyTutorScreen from '../../features/App/Student/MyTutor/MyTutorScreen';
import HistoryScreen from '../../features/App/Student/History/HistoryScreen';
import StudentSignIn from '../../features/Auth/StudentSignIn/StudentSignIn';
import HelpScreen from '../../features/App/Student/Help/HelpScreen';
import { APP_ROUTES } from './Routes';
import { ReactComponent as Dashboard } from '../icons/dashboard-img.svg';
import { ReactComponent as History } from '../icons/history.svg';
import { ReactComponent as Mytutor } from '../icons/mytutor.svg';
import { ReactComponent as Help } from '../icons/help.svg';
import StudentLoginScreen from '../../features/Auth/StudentLogin/StudentLoginscreen';
import FindTutorScreen from '../../features/App/Student/Dashboard/FindTutor/FindTutorScreen';
export const StudentMenu = [
  {
    key: 'Dashboard',
    title: 'Dashboard',
    path: APP_ROUTES.DASHBOARD,
    component: DashboardScreen,
    itemIcon: Dashboard,
  },
  {
    key: 'My Tutor',
    title: 'My Tutor',
    path: APP_ROUTES.MYTUTOR,
    component: MyTutorScreen,
    itemIcon: Mytutor,
  },
  {
    key: 'HistoryScreen',
    title: 'History',
    path: APP_ROUTES.HISTORY,
    component: HistoryScreen,
    itemIcon: History,
  },

  {
    key: 'Help',
    title: 'Help',
    path: APP_ROUTES.HELP,
    component: HelpScreen,
    itemIcon: Help,
  },
  // {
  //   key: 'Login',
  //   title: 'Login',
  //   path: APP_ROUTES.LOGIN,
  //   component: StudentLoginScreen,
  // },

  // {
  //   key: 'SignIn',
  //   title: 'signIn',
  //   path: APP_ROUTES.SIGNIN,
  //   component: StudentSignIn,
  // },
  // {
  //   key: 'FindTutor',
  //   title: 'FindTutor',
  //   path: APP_ROUTES.FINDTUTOR,
  //   component: FindTutorScreen,
  // },
];
export const TutorMenu = [
  {
    key: 'My Tutor',
    title: 'My Tutor',
    path: '/MyTutor',
    component: MyTutorScreen,
    itemIcon: Mytutor,
  },
];
