import React from 'react';
import { Row, Col } from 'antd';
import './bookingConfirmed.less';
import bookingConfirmed from '../../../../common/icons/bookingConfirmed.png';
import arrow from '../../../../common/images/arrow.png';
import CustomButton from '../../../../common/ui/Button/CustomButton';
import Typography from 'antd/es/typography/Typography';

const BookingConfirmed = () => {
  return (
    <div>
      <div className='container'>
        <Row justify='left' className='row_1'>
          <div className='img_arrow'>
            <img src={arrow} alt='My Image' />
          </div>
        </Row>

        <div className='wrapper'>
          <Row justify='center'>
            <Col xs={24} sm={24} md={24} lg={24} className='col_1'>
              <div className='img_bookingConfirmed'>
                <img src={bookingConfirmed} alt='My Image' />
              </div>
            </Col>
          </Row>
          <Row justify='center'>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Typography className='T_BOOKING_CONFIRMED'>
                Your Booking is Confirmed!
              </Typography>
            </Col>
          </Row>
          <Row justify='center' className='BOOKING_SCHEDULED'>
            <Col span={24}>
              <Typography className='T_BOOKING_SCHEDULED'>
                Your class has been scheduled,
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className='T_BOOKING_SCHEDULED'>
                Thank you for Booking Class with ELITEs!
              </Typography>
            </Col>
          </Row>
          <Row justify='center'>
            <CustomButton label={'GO BACK'} className={'button_go_back'} />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmed;
