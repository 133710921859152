import React from 'react';
import {  Table  } from 'antd';
import './History.less';
import person from "../../../../common/images/profile.png";
const data = [
  {
  
    orderId: "#12321",
    tutor: "John Doe",
    classes: "ACT",
    amount: "$50",
    dateAndTime: "2023-05-15 10:00",
    action: "View Receipt",
  },
  {
   
    orderId: "#12321",
    tutor: "John Doe",
    classes: "SAT",
    amount: "$70",
    dateAndTime: "2023-05-16 11:00",
    action: "View Receipt",
  },
  {
   
    orderId: "#12321",
    tutor: "John Doe",
    classes: "SAT",
    amount: "$70",
    dateAndTime: "2023-05-16 00:09",
    action: "View Receipt",
  },
  {
   
    orderId: "#12321",
    tutor: "John Doe",
    classes: "SAT",
    amount: "$70",
    dateAndTime: "2023-05-16 00:09",
    action: "View Receipt",
  },
  {
   
    orderId: "#12321",
    tutor: "John Doe",
    classes: "SAT",
    amount: "$70",
    dateAndTime: "2023-05-16 00:09",
    action: "View Receipt",
  },
  {
   
    orderId: "#12321",
    tutor: "John Doe",
    classes: "SAT",
    amount: "$70",
    dateAndTime: "2023-05-16 00:09",
    action: "View Receipt",
  },
  {
   
    orderId: "#12321",
    tutor: "John Doe",
    classes: "SAT",
    amount: "$70",
    dateAndTime: "2023-05-16 00:09",
    action: "View Receipt",
  },
  {
   
    orderId: "#12321",
    tutor: "John Doe",
    classes: "SAT",
    amount: "$70",
    dateAndTime: "2023-05-16 00:09",
    action: "View Receipt",
  },
  {
   
    orderId: "#12321",
    tutor: "John Doe",
    classes: "SAT",
    amount: "$70",
    dateAndTime: "2023-05-16 00:09",
    action: "View Receipt",
  },
  {
   
    orderId: "#12321",
    tutor: "John Doe",
    classes: "SAT",
    amount: "$70",
    dateAndTime: "2023-05-16 00:09",
    action: "View Receipt",
  },
];

const columns = [
  { title: "Order ID", dataIndex: "orderId", key: "orderId" ,  width: '16%'},
  {
    title: "Tutors",
    dataIndex: "tutor",
    key: "tutor",
    width: '16%',
    render: (image, record) => (
      <div className="text-img">
        <img src={person} alt="Avatar" className="tutor-img" />
        <span className="tutor-name">{record.tutor}</span>
      </div>
    ),
  },
  { title: "Classes", dataIndex: "classes", key: "classes",width: '16%'},
  { title: "Amount", dataIndex: "amount", key: "amount" , width: '16%'},
  { title: "Date and Time", dataIndex: "dateAndTime", key: "dateAndTime" , width: '16%', 
  render: (text) => <span className='date-time' >{text}</span>,},
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (text) => (
      <a href="#" className="view">
        {text}
      </a>
    ), width: '18%'
  },
];


function HistoryTable() {
  return (
    <div className="table-container">
    <Table
      dataSource={data}
      columns={columns}
      className="custom-table"
      rowClassName="custom-row"
      size="small"
     
      scroll={{ x: true }}
      responsive
      
    />
  </div>
  )
}

export default HistoryTable
