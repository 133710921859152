import React from 'react';
import { Card, Image, Typography } from 'antd';
import CustomButton from '../Button/CustomButton';

const FindTutorCards= ({  contentName,ButtonLabel,TypographyHeadingClass, ButtonClass ,className ,tutorImage,contentBodyEdu,contentBodySub,TypographyClass}) => {
  return (
    <Card  className={className}
    hoverable
    cover={<Image alt="example" src={tutorImage}  />}

    bordered={false}>  
      <Typography className={TypographyHeadingClass}>{contentName}</Typography>
      <Typography className={TypographyClass}>Education:{contentBodyEdu}</Typography>
      <Typography className={TypographyClass}>Subject:{contentBodySub}</Typography>
      <CustomButton  label={ButtonLabel} className={ButtonClass}/>
    </Card>
  );
};

export default FindTutorCards