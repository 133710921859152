import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import './dashboard.less';
import { Col, Row, Typography, Image } from 'antd';
import CustomButton from '../../../../common/ui/Button/CustomButton';
import SvgConverter from '../../../../common/ui/SvgConvertor';
// import { ReactComponent as ListImage } from '../../../../common/icons/upcoming-image.svg';
import ListImage from '../../../../common/icons/upcoming-image.svg';
import ListContent from './ListContent';
const UpcomingLessons = () => {
  const upcomingData = [
    {
      time: '8:00-8:30',
    },
    {
      time: '8:00-12:30',
    },
  ];
  return (
    <Row justify={'center'} className='lessons-container'>
      <Col
        span={24}
        style={{ backgroundColor: 'white', border: '2px solid white' }}
      >
        <Typography className='lessons-heading'>Upcoming Lessons</Typography>
      </Col>
      <Col span={24} className='lessons-list-row'>
        <Row>
          {upcomingData.map((data) => (
            <ListContent time={data?.time} />
          ))}
        </Row>
      </Col>
    </Row>
  );
};
export default UpcomingLessons;
