import { Collapse, Typography, theme } from 'antd';
import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const Collapsible = ({
  header,
  bordered,
  size,
  className,
  label = "",
  expandIconPosition
}) => {
  const { token } = theme.useToken();

  return (
    <Collapse
     className={className}
      bordered={bordered}
      size={size}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{
        background: token.colorBgContainer,
      }}
      expandIconPosition={expandIconPosition}>
      <Panel
        header={header}
        key="1">
        <Typography>{label}</Typography>
      </Panel>
    </Collapse>
  );
};

export default Collapsible;