import { Image, Typography } from 'antd'
import React from 'react'
import "./StudentSignIn.less";
  import Elites_Logo from "../../../common/images/Elites_Logo 1.png";
function StudentSignInCardHeader() {
  return (<div>
    <Typography className="custom-typography">
    <Image src={Elites_Logo} className="images" />
    Learner
  </Typography>
<Typography className="custom-typography custom-typography-head">Create a ELITEs Account</Typography>
</div>
  )
}

export default StudentSignInCardHeader
