import React from 'react'
import './FindTutorScreen.less'
import { Card, Col, Divider, Row, Typography } from 'antd'
import DropDownMenu from '../../../../../common/ui/DropDownMenu/DropDownMenu'
import { ReactComponent as Arrow } from '../../../../../common/images/Arrow.svg';
import { ReactComponent as University } from '../../../../../common/icons/University.svg'
import { ReactComponent as HighSchool } from '../../../../../common/icons/HighSchool.svg'
import { ReactComponent as English } from '../../../../../common/icons/English.svg'
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../../common/constants/Routes';
import CustomButton from '../../../../../common/ui/Button/CustomButton';
function FindTutorHeader() {
  const universityMenuItems = [
    {
      key: '1',
      label: 'SAT',
    },
    {
      key: '2',
      label: 'ACT',
    },
    {
      key: '3',
      label: 'MUIC',
     
    },
    {
      key: '4',
      label: 'CU-TEP',
     
    }, {
      key: '5',
      label: 'CU-ATDD',
     
    }, {
      key: '6',
      label: 'CU-AAT',
     
    }, {
      key: '7',
      label: 'GED',
     
    },
  ];

  const HighSchoolMenuItems = [
    {
      key: '1',
      label: 'ICGSE',
      children: [
        {
          key: '1-1',
          label: 'MATHS',
        },
        {
          key: '1-2',
          label: 'CHEMISTRY',
        },
        {
          key: '1-3',
          label: 'PHYSICS',
        },
        {
          key: '1-4',
          label: 'ENGLISH',
        },
        {
          key: '1-5',
          label: 'BIOLOGY',
        },
      ],
    },
    {
      key: '2',
      label: 'A-LEVEL',
      children: [
        {
          key: '2-1',
          label: 'MATHS',
        },
        {
          key: '2-2',
          label: 'CHEMISTRY',
        },
        {
          key: '2-3',
          label: 'PHYSICS',
        },
        {
          key: '2-4',
          label: 'ENGLISH',
        },
        {
          key: '2-5',
          label: 'BIOLOGY',
        },
      ],
    },
    {
      key: '3',
      label: 'iB',
      children: [
        {
          key: '3-1',
          label: 'MATHS',
        },
        {
          key: '3-2',
          label: 'CHEMISTRY',
        },
        {
          key: '3-3',
          label: 'PHYSICS',
        },
        {
          key: '3-4',
          label: 'ENGLISH',
        },
        {
          key: '3-5',
          label: 'BIOLOGY',
        },
      ],
    },
    {
      key: '4',
      label: 'AP',
      children: [
        {
          key: '4-1',
          label: 'MATHS',
        },
        {
          key: '4-2',
          label: 'CHEMISTRY',
        },
        {
          key: '4-3',
          label: 'PHYSICS',
        },
        {
          key: '4-4',
          label: 'ENGLISH',
        },
        {
          key: '4-5',
          label: 'BIOLOGY',
        },
      ],
    },
  ];

  const EnglishMenuItems=[
    {
      key: '1',
      label: 'TOEFL',
    },
    {
      key: '2',
      label: 'IELTS',
    },
  ]
  return (

      <div className="centered-card"  >
       
       <Row> <Col>
       <Link to={APP_ROUTES.DASHBOARD}>
     <CustomButton icon={<Arrow/>} className="find-but"/>
     </Link>
   </Col>
   </Row>

    <Row justify={'center'}> 
     <Col className='find-head'>
     <Typography className='headline_find_tutor' >
       Find your tutor
     </Typography>
     </Col>

      </Row>

      <Row justify={'center'}>
     <Col  lg={22}  xl={18} className='find-head' justify='center' >
       <Row  className='find-group-dropdown' justify={'space-evenly'} >
         <Col md={22} lg={7} >
         <DropDownMenu icon={<University/>}  label={"University Admission"}  items={universityMenuItems}  className={"find-dropdown"}      />
         </Col>
          <Col sm={0} xs={0} md={0} lg={1}>
       <Divider type="vertical"  className='find-divider'/>
       </Col> 
       <Col md={22}  lg={7}>
     <DropDownMenu icon={<HighSchool/>}  label={"High School"}  items={HighSchoolMenuItems}  className={"find-dropdown"}      />
     </Col>
      <Col sm={0} xs={0} md={0} lg={1}>
     <Divider type="vertical" className='find-divider' />
     </Col>
      <Col md={22}  lg={7}>
     <DropDownMenu icon={< English />}  label={"English Proficiency"}  items={EnglishMenuItems}  className={"find-dropdown"}      />
     </Col> 
       </Row>
    
     </Col>
     </Row>
      <Row>
       <Col md={2} lg={1}></Col>
     <Col className='find-head'>
    <Typography className='find-filter'>Filter by subject <b>“IGCSE”</b> 118 Tutors available</Typography>
     </Col>
     </Row>
    </div>
  )
}

export default FindTutorHeader
