import { Calendar, theme } from 'antd';
import { Dayjs } from 'dayjs';
import React, { UseEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
const onPanelChange = (value, mode) => {
  console.log(value.format('YYYY-MM-DD'), mode);
};

const StudentCalendar = () => {
  const { token } = theme.useToken();

  const wrapperStyle = {
    width: 300,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const scheduledDates = ['2023-05-04', '2023-05-05', '2023-05-06'];
  const completedDates = ['2023-05-22', '2023-05-23', '2023-05-24'];
  const [month, setMonth] = useState('');
  const renderHeader = ({ value, type, onChange }) => {
    // setMonth(value);
    const headerText =
      type === 'month'
        ? value.format('MMMM YYYY')
        : value.format('D MMMM YYYY');
    console.log('headerText', headerText);
    setMonth(value.format('MM'));
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{headerText}</div>
        <div>
          <button onClick={() => onChange(value.clone().subtract(1, type))}>
            <LeftOutlined />
          </button>
          <button onClick={() => onChange(value.clone().add(1, type))}>
            <RightOutlined />
          </button>
        </div>
      </div>
    );
  };

  const renderDateCell = (date) => {
    const dateValue = date.date();

    const isScheduledDate = scheduledDates.includes(date.format('YYYY-MM-DD'));
    const isCompletedDate = completedDates.includes(date.format('YYYY-MM-DD'));
    // const currentMonth = month.format('YYYY-MM-DD');
    console.log('scheduledDates', scheduledDates);
    let backgroundColor = 'none';
    let color = 'black';

    if (isScheduledDate) {
      backgroundColor = '#5C83D4';
      color = 'white';
    } else if (isCompletedDate) {
      backgroundColor = '#FB0080';
      color = 'white';
    }

    const borderRadius = '50%';
    const width = '50%';

    return (
      <div style={{ backgroundColor, borderRadius, width, color }}>
        <div>{dateValue}</div>
      </div>
    );
  };

  return (
    <div style={wrapperStyle}>
      <Calendar
        fullscreen={false}
        onPanelChange={onPanelChange}
        headerRender={renderHeader}
        dateFullCellRender={renderDateCell}
      />
    </div>
  );
};

export default StudentCalendar;
