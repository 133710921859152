import { Col, Row } from 'antd'
import React from 'react'
import './FindTutorScreen.less'
import CustomButton from '../../../../../common/ui/Button/CustomButton'
import FindTutorCards from '../../../../../common/ui/Card/FindTutorCards'
import profile from '../../../../../common/images/profile1.png'
function FindTutorCard() {
    const cardData = [
        { name: 'Jaxson',Eduction:"MUIC" ,Subject:"IGCSE" ,imageUrl:profile},
        { name: 'Erin Ekstrom' ,Eduction:"MUIC" ,Subject:"IGCSE" ,imageUrl:profile},
        { name: 'Haylie  Donin',Eduction:"MUIC" ,Subject:"IGCSE" ,imageUrl:profile },
        { name: 'Miracle workman' ,Eduction:"MUIC" ,Subject:"IGCSE" ,imageUrl:profile},
        { name: 'Cheyenne Gouse',Eduction:"MUIC" ,Subject:"IGCSE" ,imageUrl:profile },
        { name: 'Jaxson',Eduction:"MUIC" ,Subject:"IGCSE" ,imageUrl:profile},
        { name: 'Erin Ekstrom' ,Eduction:"MUIC" ,Subject:"IGCSE" ,imageUrl:profile},
        { name: 'Haylie  Donin',Eduction:"MUIC" ,Subject:"IGCSE" ,imageUrl:profile },
        { name: 'Miracle workman' ,Eduction:"MUIC" ,Subject:"IGCSE" ,imageUrl:profile},
        { name: 'Cheyenne Gouse',Eduction:"MUIC" ,Subject:"IGCSE" ,imageUrl:profile },
      ];
  return (
    <div>
         <Row  className='find-card-row'  >
       
       { cardData.map((data, index)=>{
      return ( <Col xs={24} sm= {24} md={12} lg={8} xl={6} xxl={4}><FindTutorCards   key={index} 
        TypographyClass="find-edu-sub"
        TypographyHeadingClass="find-name-heading"
      className="find-card"  
      tutorImage={data.imageUrl} 
      contentName={data.name}
      contentBodyEdu={data.Eduction} 
      contentBodySub={data.Subject} 
      ButtonClass={"find-card-btn"} 
      ButtonLabel={"Learn Now"}/> </Col>)
       })}

     </Row>
     <Row  justify={'center'}>
     <CustomButton label={'Load more'} className={"find-but-load"}/>
     </Row>
    </div>
  )
}

export default FindTutorCard
