import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import AppRoutes from './Layout/AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRoutes from './Layout/AuthRoutes';
import CommonRoutes from './Layout/CommonRoutes';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   alert();
  //   const checkAuthentication = () => {
  //     const user = localStorage.getItem('user');
  //     if (user) {
  //       setIsAuthenticated(true);
  //     } else {
  //       setIsAuthenticated(false);
  //     }
  //   };

  //   checkAuthentication();
  // });

  return (
    <Router>
      <CommonRoutes />

      {/* {isAuthenticated ? <AppRoutes /> : <AuthRoutes />} */}
    </Router>
  );
}

export default App;
