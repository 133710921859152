import React from 'react'
import Collapsible from '../../../../common/ui/Collapsible/Collapsible'
import {  Typography } from 'antd'
import './HelpScreen.less';

function HelpScreen() {
  return (
    <div>
        <div className='help'> 
        <Typography className='contact-us help-header'>Contact us</Typography>
        <Typography className='email help-header' >hello@elites.co</Typography>
        <Typography className='email help-header'>Monday to Friday : 8.00 - 19.00</Typography>
        <Collapsible className={"collapsible"} header="How Elite's Work?" size={"large"} bordered={false} expandIconPosition={"end"} label={"Welcome to our one-on-one tutoring website! We are excited to have you here and look forward to helping you achieve your academic goals. Before we get started, please take a few minutes to review our terms of service."}/>
        <Collapsible className={"collapsible"} header="What can i get help with?" size={"large"} bordered={false} expandIconPosition={"end"} label={"Welcome to our one-on-one tutoring website! We are excited to have you here and look forward to helping you achieve your academic goals. Before we get started, please take a few minutes to review our terms of service."}/>
        <Collapsible className={"collapsible"} header="Where it take place" size={"large"} bordered={false} expandIconPosition={"end"} label={"Welcome to our one-on-one tutoring website! We are excited to have you here and look forward to helping you achieve your academic goals. Before we get started, please take a few minutes to review our terms of service."}/>
        <Collapsible className={"collapsible"} header="How to contact support?" size={"large"} bordered={false} expandIconPosition={"end"} label={"Welcome to our one-on-one tutoring website! We are excited to have you here and look forward to helping you achieve your academic goals. Before we get started, please take a few minutes to review our terms of service."}/>
        <Collapsible className={"collapsible"} header="Is my session safe?" size={"large"} bordered={false} expandIconPosition={"end"} label={"Welcome to our one-on-one tutoring website! We are excited to have you here and look forward to helping you achieve your academic goals. Before we get started, please take a few minutes to review our terms of service."}/>
     </div>
    </div>
  )
}

export default HelpScreen
