import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import './dashboard.less';
import { Col, Row, Typography } from 'antd';
import CustomButton from '../../../../common/ui/Button/CustomButton';
import ListContent from './ListContent';
const CurrentDay = () => {
  return (
    <Row justify={'center'}>
      <ListContent />
    </Row>
  );
};
export default CurrentDay;
