import { Col, Divider, Row, Typography } from 'antd'
import React from 'react'
import "./StudentSignIn.less";
import { ReactComponent as IconSvg } from "../../../common/images/icons8-line.svg";
import { ReactComponent as GoogleIcon } from "../../../common/images/icons8-google.svg";

function StudentSignInCardBody() {
  return (
    <div>
      <Row className='button'>
             <Row className=' button-content'>
              <Col>
                <IconSvg className='button-icon ' />
              </Col>
              <Col>
                <span className='button-text'>Continue with Line</span>
              </Col>
             </Row>
              </Row>
              <Row className='button'>
             <Row className=' button-content'>
              <Col>
                <GoogleIcon className='button-icon ' />
              </Col>
              <Col>
                <span className='button-text'>Continue with Google</span>
              </Col>
             </Row>
              </Row>
              <Row>
                <Col span={11}>
                  <Divider className="divider-class" />
                </Col>
                <Col span={2}><Typography className='sign_or'>or</Typography></Col>
                <Col span={11}>
                  <Divider className="divider-class" />
                </Col>
              </Row>
             <Typography className="custom-typography-head">Account Information</Typography>
    </div>
  )
}

export default StudentSignInCardBody
