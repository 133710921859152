import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  Divider,
  Image,
  Layout,
  Typography,
} from 'antd';
import './StudentLogin.less';
import { ReactComponent as Line } from '../../../common/icons/line.svg';
import { ReactComponent as Google } from '../../../common/icons/google.svg';

import { Header } from 'antd/es/layout/layout';
import Elites_Logo from '../../../common/icons/Elites_Logo.png';
import CustomButton from '../../../common/ui/Button/CustomButton';
import LoginForm from './LoginForm';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
const StudentLoginScreen = () => {
  localStorage.setItem('login', false);
  return (
    <Layout>
      <Row justify={'center'}>
        <Col span={24}>
          <Header className='headerStyles'>
            <CustomButton
              type='primary'
              label={'TUTOR LOGIN'}
              className='header_button'
            />
          </Header>
        </Col>

        <Col
          xs={22}
          sm={16}
          md={12}
          lg={8}
          xl={6}
          xxl={5}
          style={{ marginTop: '5%' }}
        >
          <Card>
            <Row>
              <Col>
                <Image src={Elites_Logo} className='images' />
              </Col>
              <Col style={{ marginTop: '4%' }}>
                <Typography>Learner</Typography>
              </Col>
            </Row>
            <Row style={{ marginTop: '10%', fontWeight: '600' }}>
              <Typography style={{ fontWeight: '700' }}>Login</Typography>
            </Row>
            <Row className='button'>
              <Col>
                <Line className='button-icon' />
              </Col>
              <Col>
                <span className='button-text'>Continue With Line</span>
              </Col>
            </Row>
            <Row className='button'>
              <Col>
                <Google className='button-icon' />
              </Col>
              <Col>
                <span className='button-text'>Continue With Google</span>
              </Col>
            </Row>
            <Row>
              <Divider>or</Divider>
            </Row>
            <LoginForm />{' '}
            <Row style={{ justifyContent: 'center' }}>
              <Col> No account yet?</Col>
              <Col style={{ marginLeft: '2%' }}>
                <Link to={APP_ROUTES.SIGNUP}>
                  <a style={{ color: '#fb0080' }}>Register</a>
                </Link>
              </Col>
            </Row>
          </Card>{' '}
        </Col>
        <Col span={24}>
          <Row className='row-head'></Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default StudentLoginScreen;
