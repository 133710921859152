import React from 'react';
import { InlineWidget } from 'react-calendly';

const Calendar = () => {
  const handleEventScheduled = () => {
    console.log('Event scheduled');
    // Handle any additional logic when an event is scheduled
  };

  return (
    <div>
      {/* Display other content here */}
      <InlineWidget
        url='https://calendly.com/eliteschedule/elite'
        styles={{ height: '532px' }}
        prefill={{ name: 'John Doe', email: 'johndoe@example.com' }}
        utm={{
          utmCampaign: 'Spring Sale',
          utmSource: 'Facebook',
          utmMedium: 'Ad',
        }}
        onEventScheduled={handleEventScheduled}
      />
    </div>
  );
};

export default Calendar;
