import { useState, useEffect } from 'react';
import {
  Col,
  Layout,
  Menu,
  Row,
  Typography,
  Image,
  Select,
  Button,
  Dropdown,
  Drawer,
} from 'antd';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { StudentMenu, TutorMenu } from '../common/constants/Menu';
import { CaretDownOutlined, MenuOutlined } from '@ant-design/icons';
import { ReactComponent as Profileimage } from '../common/icons/Elites_Logo 1.svg';
import tutor_img from '../common/icons/userProfile.png';
import Elites_Logo from '../common/icons/Elites_Logo.png';
import './AppLayout.less';
import SvgConverter from '../common/ui/SvgConvertor';
import LabelWithIcon from '../common/ui/LabelWithIcon';
import { SmileOutlined } from '@ant-design/icons';
import { APP_ROUTES, WITHOUT_LAYOUT } from '../common/constants/Routes';
const items = [
  {
    label: (
      <Typography style={{ fontWeight: '800', padding: '12px' }}>
        Welcome ELITEs
      </Typography>
    ),
    key: '0',
  },
  {
    label: <Typography style={{ padding: '12px' }}>View Profile</Typography>,
    key: '1',
  },
  {
    label: <Typography style={{ padding: '12px' }}>Change Password</Typography>,
    key: '2',
  },
  {
    label: <Typography style={{ padding: '12px' }}>Logout</Typography>,
    key: '3',
  },
];

const { Header, Content, Sider } = Layout;
const { Option } = Select;
const UserId = localStorage.getItem('login-user');
const AppLayout = () => {
  console.log('login');
  const [menuHeader, setMenuHeader] = useState('Dashboard');
  const [collapsed, setCollapsed] = useState(false);

  const [menuItems, setMenuItems] = useState(StudentMenu);
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].key);
  const navigate = useNavigate();
  const location = useLocation();
  const w = window.innerWidth;

  const handleMenuClick = (menuItem) => {
    setMenuHeader(menuItem.title);
    setSelectedMenuItem(menuItem.key);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const menuItem = menuItems.find((item) => item.path === location.pathname);
    if (menuItem) {
      setMenuHeader(menuItem.title);
    }
  }, [location]);
  useEffect(() => {
    if (UserId === '1') {
      setMenuItems(StudentMenu);
    } else if (UserId === '2') {
      setMenuItems(TutorMenu);
    }
  }, [UserId]);
  useEffect(() => {
    if (UserId === '1') {
      setSelectedMenuItem(StudentMenu[0].key); // Set first menu item for student
    } else if (UserId === '2') {
      setSelectedMenuItem(TutorMenu[0].key); // Set first menu item for tutor
    }
  }, [UserId]);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  console.log('menuHeader', menuHeader);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  if (WITHOUT_LAYOUT.includes(location.pathname)) {
    return <Outlet />;
  }

  return (
    <Layout>
      {w < 768 ? (
        <Layout>
          <Sider
            collapsed={collapsed}
            style={{ backgroundColor: 'transparent' }}
          >
            {/* <div className='demo-logo-vertical' /> */}
            <Menu
              defaultSelectedKeys={['1']}
              // items={menuItems} // Remove this line
              // onClick={() => setCollapsed(true)} // Remove this line
              className='content'
            >
              {menuItems.map((item) => (
                <Menu.Item
                  key={item.key}
                  className={menuHeader === item.title ? 'selected' : ''}
                  // Add this line to set the icon for each menu item
                  icon={
                    <SvgConverter
                      Icon={item.itemIcon}
                      color='red'
                      className={
                        selectedMenuItem === item.key
                          ? 'pink-icon'
                          : 'normal-icon'
                      }
                    />
                  }
                >
                  <Link
                    to={item.path}
                    onClick={() => {
                      handleMenuClick(item);
                      navigate(item.path);
                    }}
                  >
                    {item.title}
                  </Link>
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Layout>
            <Header
              style={{
                padding: 0,
                backgroundColor: 'transparent',
                // Update with your desired background color
              }}
            >
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col>
                  <Row>
                    <Col>
                      <Image src={Elites_Logo} />
                    </Col>
                    <Col>
                      <Typography style={{ marginTop: '35px' }}>
                        Learner
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Typography
                      style={{
                        marginTop: '24px',
                        marginLeft: '20px',
                        fontSize: '20px',
                        fontWeight: 700,
                      }}
                    >
                      {menuHeader}
                    </Typography>
                  </Row>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='primary'
                    onClick={showDrawer}
                    className='drawer-button'
                  >
                    <MenuOutlined />
                  </Button>
                </Col>
                <Drawer
                  title='Welcome ELITEs'
                  placement='right'
                  closable={false}
                  onClose={onClose}
                  visible={visible}
                  width={300}
                >
                  <Typography>View Profile</Typography>{' '}
                  <Typography>Change Password</Typography>{' '}
                  <Typography>Logout</Typography>
                </Drawer>
              </Row>

              {/* Button to trigger the drawer */}
            </Header>
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,

                // Update with your desired background color
              }}
              className='content'
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Layout>
          <Header
            className='header'
            style={{
              padding: 0,
              backgroundColor: 'transparent',
              // Update with your desired background color
            }}
          >
            <Row>
              <Col>
                <Row>
                  <Col>
                    <SvgConverter Icon={Profileimage} className='elites-logo' />
                    {/* <Image src={Elites_Logo} /> */}
                  </Col>
                  <Col>
                    <Typography style={{ marginTop: '35px' }}>
                      Learner
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Typography
                  style={{
                    marginTop: '24px',
                    marginLeft: '50px',
                    fontSize: '20px',
                    fontWeight: 700,
                  }}
                >
                  {menuHeader}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  marginTop: '10px',
                }}
              >
                <img src={tutor_img} alt='My Image' className='tutor_img' />
              </Col>
              <Col
                style={{
                  marginLeft: '10px',
                  marginBottom: '10px',
                }}
              >
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={['click']}
                  overlayStyle={{
                    height: '237px',
                    width: '274px',
                    marginTop: '200px',
                  }}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ color: 'inherit' }}
                  >
                    {' '}
                    Honwai <CaretDownOutlined />
                  </a>
                </Dropdown>
              </Col>
            </Row>
          </Header>
          <Layout>
            <Sider
              style={{
                backgroundColor: 'transparent',
              }}
              width={230}
            >
              <Menu mode='vertical' className='content'>
                {menuItems.map((item) => (
                  <Menu.Item
                    key={item.key}
                    className={menuHeader === item.title ? 'selected' : ''}
                  >
                    <Row
                      style={{
                        alignItems: 'center',
                      }}
                    >
                      <Col>
                        <SvgConverter
                          Icon={item.itemIcon}
                          color='red'
                          className={
                            selectedMenuItem === item.key
                              ? 'pink-icon'
                              : 'normal-icon'
                          }
                        />
                      </Col>{' '}
                      <Col
                        style={{
                          justifyContent: 'center',
                          marginBottom: '14px',
                        }}
                      >
                        <Link
                          to={item.path}
                          onClick={() => {
                            handleMenuClick(item);
                            navigate(item.path);
                          }}
                          style={{ marginLeft: '10px' }}
                        >
                          {item.title}
                        </Link>
                      </Col>
                    </Row>
                  </Menu.Item>
                ))}
              </Menu>
            </Sider>
            <Layout>
              <Content className='content'>
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};

export default AppLayout;
