import React from "react";
import { Form, Input } from "antd";
const FormInput = ({
  placeholder,
  value,
  className,
  type,
  name,
  onChange,
  rules = [],
  label = "",
  labelCol,
  wrapperCol,
  addonBefore,
}) => {
  return (
    <Form.Item
      name={name}
      rules={rules}
      label={label}
      labelCol={{
        span: labelCol,
      }}
      wrapperCol={{
        span: wrapperCol,
      }}
    >
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        className={className}
        addonBefore={addonBefore}
      />
    </Form.Item>
  );
};
export default FormInput;