import { Form, Typography } from 'antd'
import React, { useState } from 'react'
import CustomButton from '../../../common/ui/Button/CustomButton'
import FormInput from '../../../common/ui/Input/FormInput'
import FormCheckBox from '../../../common/ui/CheckBox/FormCheckBox';
function StudentSignInForm() {
    const onFinish = (values) => {
        console.log("Received values of form: ", values);
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
      };
    
      const onForgotPassword = () => {
        console.log("Forgot Password clicked!");
      };
  const [checked, setChecked] = useState(false);
  const [check, setCheck] = useState(true);
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  const handleChanged = (e) => {
    setCheck(e.target.checked)
  };
  return (
    <Form
    name="login"
    initialValues={{ remember: true }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
  >
    <FormInput
        type="text"
        className="input-box" placeholder="Full Name"
        name={"Full Name"}
        rules={[
    {
      required: false,
      message:"enter full name",
    },
    ]}
              />
              <FormInput
                    type="text"
                    className="input-box" placeholder="Email"
                    name={"email"}
                    rules={[
                {
                  required: false,
                  message:"enter email",
                },
                ]}
              />
              <FormInput
                    type="text"
                    className="input-box" placeholder="Phone Number"
                    name={"Phone number"}
                    rules={[
                {
                  required: false,
                  message:"enter Phone number",
                },
                ]}
              />
              <FormInput
              type="text"
              className="input-box" placeholder="Password"
              name={"Password"}
              rules={[
          {
            required: false,
            message:"enter Password",
          },
          ]}
        /><FormInput
        type="text"
        className="input-box" placeholder="Confirm Password"
        name={"Confirm Password"}
        rules={[
    {
      required: false,
      message:"enter Confirm Password",
    },
    ]}
     />
     <FormCheckBox  label={"I have read and accept #ELITEs Terms and Conditions *#" }  className={'form-check-box'} checked={check} onChange={handleChanged}   />
     <FormCheckBox label={"I have read and accept #Refund Policy*#"} className={'form-check-box'}  checked={checked}  onChange={handleChange} />
    <Form.Item>
      <CustomButton className=" but_col" htmlType="submit" label={"CREATE AN ACCOUNT"} block={true}   />
    </Form.Item>
      <Typography className="custom-typography">
      Already have an account?{' '}
        <a href="#"  onClick={onForgotPassword} className="login-sign-a">
          Login
        </a>
      </Typography>
   
  </Form>
  )
}

export default StudentSignInForm
