import { Button } from 'antd';
import React from 'react';

const CustomButton = ({
  label,
  type,
  className,
  onClick,
  size,
  icon,
  htmlType,
  shape,
  block,
}) => {
  return (
    <Button
      type={type}
      className={className}
      icon={icon}
      onClick={onClick}
      size={size}
      htmlType={htmlType}
      shape={shape}
      block={block}
    >
      {label}
    </Button>
  );
};
export default CustomButton;
