import React, { useState } from 'react';

import { Menu, Dropdown, Space } from 'antd';

const DropDownMenu = ({ items , label ,className,icon}) => {
  const [visible, setVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const handleMenuClick = (e) => {
    console.log('Selected key:', e.key);
    setSelectedKey(e.key);
  };
  const renderMenuItems = (items) => {
    return items.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu
            key={item.key}
            title={item.label}
            onTitleClick={handleMenuClick}
          >
            {renderMenuItems(item.children)}
          </Menu.SubMenu>
        );
      }
      return (
        <Menu.Item key={item.key} onClick={handleMenuClick}>
          {item.label}
        </Menu.Item>
      );
    });
  };
  const menu = <Menu onClick={handleMenuClick}>{renderMenuItems(items)}</Menu>;
  return (
    <Dropdown 
    className={className}
      overlay={menu}
      visible={visible}
      onVisibleChange={(flag) => setVisible(flag)}
      trigger={['click']}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space style={{padding:"12px"}}>
          {icon}
          {label}
        </Space>
      </a>
    </Dropdown>
  );
};
export default DropDownMenu

