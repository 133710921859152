import React from 'react'
import CustomButton from '../../../common/ui/Button/CustomButton'
import "./StudentSignIn.less";
import { Header } from 'antd/es/layout/layout';
function StudentSignUpHeader() {
  return (

      <Header className="headerStyles">
              <div className="header-content">
                <CustomButton className="header_button" label={"Tutor Login"}/>
              </div>
            </Header>
    
  )
}

export default StudentSignUpHeader
