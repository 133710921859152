import { Checkbox, Form } from 'antd';
import React from 'react';

const FormCheckBox = ({
  checked,
  className,
  name,
  onChange,
  rules = [],
  label = '',
  labelCol,
  wrapperCol,
}) => {
  const getLabelParts = () => {
    const parts = label.split('#');

    return parts.map((part, index) => {
      if (index === 0) {
        return (
          <span
            key={index}
            style={{
              color: '#57514E',
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '19px',
            }}
          >
            {part}
          </span>
        );
      } else if (index === 1) {
        return (
          <span
            key={index}
            style={{
              color: '#000000',
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '19px',
            }}
          >
            {part}
          </span>
        );
      }
      return null;
    });
  };

  return (
    <Form.Item
      name={name}
      rules={rules}
      labelCol={{
        span: labelCol,
      }}
      wrapperCol={{
        span: wrapperCol,
      }}
    >
      <Checkbox checked={checked} onChange={onChange} className={className}>
        {getLabelParts()}
      </Checkbox>
    </Form.Item>
  );
};

export default FormCheckBox;
