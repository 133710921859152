import {
    Row,
    Col,
    Card,
  } from "antd";
  import "./StudentSignIn.less";
import StudentSignUpHeader from "./StudentSignUpHeader";
import StudentSignInCardHeader from "./StudentSignInCardHeader";
import StudentSignInCardBody from "./StudentSignInCardBody";
import StudentSignInForm from "./StudentSignInForm";
  function StudentSignIn() {
    return (
        <Row justify={"center"}>
          <Col span={24}>
            <StudentSignUpHeader/>
          </Col>
          <Col span={24}>
            <Row className="row-head"></Row>
          </Col>
          <Col xs={22} md={14} lg={10} xl={8} xxl={6}>
            <Card  >
            <StudentSignInCardHeader/>
            <StudentSignInCardBody/>
           <StudentSignInForm/>
            </Card>
          </Col>
          <Col span={24}>
            <Row className="row-head"></Row>
          </Col>
        </Row>
    )
  }
  
 
  

export default StudentSignIn
