import React from 'react';
import HistoryTable from './HistoryTable';
import HistoryHeader from './HistoryHeader';
import { Card } from 'antd';
function HistoryScreen() {
  return  <div >
  <Card className='card'>
   < HistoryHeader/>
   <HistoryTable/>
  </Card>
</div>;
}
export default HistoryScreen;