import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import './dashboard.less';
import { Col, Row, Typography } from 'antd';
import CustomButton from '../../../../common/ui/Button/CustomButton';
const DashboardHeader = () => {
  return (
    <Row justify={'center'}>
      <Col span={12} className='student-dashboard-header'>
        <CustomButton
          label={'scheduled classess'}
          className={'tab-button-header'}
          block={true}
          type={'link'}
        />
      </Col>
      <Col span={12} className='student-dashboard-header'>
        <CustomButton
          label={'completed classess pointer'}
          className={'tab-button-header'}
          block={true}
          type={'link'}
        />
      </Col>
    </Row>
  );
};
export default DashboardHeader;
