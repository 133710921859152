import React from 'react'
import './FindTutorScreen.less'
import { Card } from 'antd'
import FindTutorHeader from './FindTutorHeader';
import FindTutorCard from './FindTutorCard';
const FindTutorScreen = () => {
 
  return (
    <div className='test'>
      <Card   cover={<FindTutorHeader/>}>
      
     <FindTutorCard/>
      </Card> 
      
    </div>
  )
}

export default FindTutorScreen
