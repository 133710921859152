import { Card, Image, Row, Col, Typography, Button, Modal } from 'antd';
import React, { useState } from 'react';
import cardimgage from '../../../../common/icons/mytutor-img.svg';
import './mytutor.less';
import CustomButton from '../../../../common/ui/Button/CustomButton';
import ReusableModal from '../../../../common/ui/Modal/CustomModal';
import Calendar from './Calender';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import BookingConfirmed from './BookingConfirmed';

const MyTutorScreen = () => {
  const cardData = [
    {
      Head: "Jaxson's profile",
      name: 'Jaxson',
      imageUrl: cardimgage,
      ClassTaken: 'ACT,SAT,A-LEVEL',
      Education: 'Master of Arts,Master of Research,Bachelor of Arts',
    },
    {
      Head: "Erin Ekstrom's profile",
      name: 'Erin Ekstrom',
      imageUrl: cardimgage,
      ClassTaken: 'ACT,SAT,A-LEVEL',
      Education: 'Master of Arts,Master of Research,Bachelor of Arts',
    },
    {
      Head: "Haylie  Donin's profile",
      name: 'Haylie  Donin',
      imageUrl: cardimgage,
      ClassTaken: 'ACT,SAT,A-LEVEL',
      Education: 'Master of Arts,Master of Research,Bachelor of Arts',
    },
    {
      Head: "Miracle workman's profile",
      name: 'Miracle workman',
      imageUrl: cardimgage,
      ClassTaken: 'ACT,SAT,A-LEVEL',
      Education: 'Master of Arts,Master of Research,Bachelor of Arts',
    },
    {
      Head: "Cheyenne Gouse's profile",
      name: 'Cheyenne Gouse',
      imageUrl: cardimgage,
      ClassTaken: 'ACT,SAT,A-LEVEL',
      Education: 'Master of Arts,Master of Research,Bachelor of Arts',
    },
  ];

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    // Perform any necessary actions when OK button is clicked
    setModalVisible(false);
  };

  const handleCancel = () => {
    // Perform any necessary actions when Cancel button is clicked
    setModalVisible(false);
  };
  return (
    <Row style={{ padding: '20px' }}>
      {cardData.map((data, index) => (
        <Col className='card-col'>
          <Card
            bordered={false}
            className='mytutor'
            style={{
              width: 180,
              height: 229,
            }}
          >
            <Row>
              <Col span={24} className='card-image'>
                <Link to={APP_ROUTES.TUTORPROFILE} state={data}>
                  <Image src={cardimgage} preview={false} />
                </Link>
              </Col>
              <Col span={24}>
                <Typography
                  style={{
                    textAlign: 'center',
                    padding: '10px',
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  {data.name}
                </Typography>
              </Col>
              <Col span={24}>
                <CustomButton
                  label={'Book Again'}
                  className={'but_col card-button'}
                  block={true}
                  onClick={showModal}
                />{' '}
                <ReusableModal
                  open={modalVisible}
                  onCancel={handleCancel}
                  onOk={handleOk}
                  width={1048}
                >
                  <Calendar />
                </ReusableModal>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
export default MyTutorScreen;
