import React from 'react';
import { Table, List, Avatar, Typography, Row, Button, Col } from 'antd';
import './tutorProfile.less';
import person from '../../../../common/images/profile.png';
import arrow from '../../../../common/images/arrow.png';
import CustomButton from '../../../../common/ui/Button/CustomButton';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const TutorProfile = (name) => {
  const location = useLocation();
  const dataittem = location.state;
  console.log('data', dataittem);

  return (
    <div className='List'>
      <List
        itemLayout='horizontal'
        dataSource={[dataittem]}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Link to={APP_ROUTES.MYTUTOR}>
                  <ArrowLeftOutlined className='arrow_icon' />
                </Link>
              }
              title={<Typography className='title'>{item.Head}</Typography>}
              description={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '20px 0px 20px -40px ',
                  }}
                >
                  <Col xs={6} sm={4} md={3} lg={1}>
                    <Avatar src={item.imageUrl} size={50} />
                  </Col>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>
                      <Typography>
                        <span className='description_t'>FULL NAME:</span>{' '}
                        {item.name}
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <span className='description_t'>Class Taken:</span>{' '}
                        {item.ClassTaken}
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <span className='description_t'>Education:</span>{' '}
                        {item.Education}
                      </Typography>
                    </li>
                  </ul>
                </div>
              }
            />
          </List.Item>
        )}
      />
      <CustomButton label={'Book Again'} className={'button_bookAgain'} />
    </div>
  );
};
export default TutorProfile;
